export function formatTextFirstLetterInCaps(input?: string): string {
  const trimmedInput = input?.trim();
  if (!trimmedInput || trimmedInput?.length === 0) {
    return '';
  }
  return trimmedInput.charAt(0).toUpperCase() + trimmedInput.slice(1).toLowerCase();
}

export function uppercaseFirstLetterOfEachWord(sentence: string): string {
  return sentence
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

// Define Schengen countries with their postal code ranges
const schengenCountriesWithPostalRanges: Record<string, [number, number][]> = {
  AT: [[1000, 9999]], // Austria
  BE: [[1000, 9999]], // Belgium
  CZ: [[10000, 79862]], // Czech Republic
  DK: [[1000, 9990]], // Denmark
  EE: [[15000, 96098]], // Estonia
  FI: [[100, 99999]], // Finland
  FR: [
    [1000, 95999],   // Mainland France (excluding DOM-TOM)
  ],
  DE: [[1000, 99999]], // Germany
  GR: [[100, 999]], // Greece
  HU: [[1000, 9985]], // Hungary
  IS: [[101, 902]], // Iceland
  IT: [[100, 98168]], // Italy
  LV: [[1001, 9999]], // Latvia
  LT: [[10001, 99999]], // Lithuania
  LU: [[1009, 9999]], // Luxembourg
  MT: [[1000, 9999]], // Malta
  NL: [[1000, 9999]], // Netherlands
  NO: [[1, 9991]], // Norway
  PL: [[1, 99999]], // Poland
  PT: [[1000, 9980]], // Portugal
  SK: [[100, 99999]], // Slovakia
  SI: [[1000, 9600]], // Slovenia
  ES: [[1000, 52080]], // Spain (incl. Canary Islands, Ceuta, Melilla)
  SE: [[10000, 99999]], // Sweden
  CH: [[1000, 9658]], // Switzerland
  LI: [[9485, 9498]], // Liechtenstein
  MC: [[98000, 98000]], // Monaco (via France, considered part of Schengen)
};

export function isSchengenArea(countryCode: string, postalCode: string) {
  countryCode = countryCode.toUpperCase();

  if (!schengenCountriesWithPostalRanges[countryCode]) {
    return false;
  }

  const postal = parseInt(postalCode, 10);

  return schengenCountriesWithPostalRanges[countryCode]?.some(
    ([start, end]) => postal >= start && postal <= end
  ) ?? false;
}

export function base64DecodeUnicode(base64Str: string) {
  const binaryString = atob(base64Str);
  const bytes = Uint8Array.from(binaryString, c => c.charCodeAt(0));
  return new TextDecoder().decode(bytes);
}