import z from 'zod';

const ZAttributeInput = z.object({
  key: z.string(),
  value: z.string(),
});

const ZCommandStatus = z.enum(['PENDING', 'CREATED', 'ERROR', 'CANCELLED', 'UPDATED', 'IDLE']);

export const ZInboundFormMessage = z.object({
  origin: z.string().optional(),
  redirectMode: z.boolean().optional(),
  figpiiData: z.string().optional(),
  emitter: z.string(),
  requestId: z.string(),
  language: z.enum(['fr', 'en', 'es', 'it', 'de']),
  source: z.enum([
    'product',
    'cart',
    'collections',
    'custom',
    'customV2',
    'product-form',
    'custom-prestashop',
  ]),
  domain: z.string().optional(),
  theme: z.string().optional(),
  sessionKey: z.string().optional().default('DEFAULT_KEY'),
  commandStatus: ZCommandStatus,
  shopId: z.string(),
  tos: z.string().optional(),
  expiration: z.number().default(0),
  countryCode: z.string().optional(),
  cart: z.object({
    id: z.string().optional(),
    discountCodes: z.string().array().optional(),
    customAttributes: z.array(ZAttributeInput).optional(),
    currencyId: z.string().optional(),
    languageId: z.string().optional(),
    source: z.string().optional(),
    lineItems: z
      .object({
        productId: z.string().optional(),
        quantity: z.number().positive(),
        variantId: z.string().optional(),
        customizationId: z.string().optional(),
        customAttributes: z.array(ZAttributeInput).optional(),
        amount: z.number().optional(),
      })
      .array()
      .optional(),
    note: z.string().optional(),
  }),
  currentUser: z
    .object({
      id: z.string().optional(),
      email: z.string().optional(),
      firstName: z.string().optional(),
      lastName: z.string().optional(),
      phone: z.string().optional(),
      address: z
        .object({
          id: z.string().optional(),
          address1: z.string().optional(),
          address2: z.string().optional(),
          city: z.string().optional(),
          zip: z.string().optional(),
          province: z.string().optional(),
          countryCode: z.string().optional(),
          country: z.string().optional(),
        })
        .optional(),
    })
    .optional(),
  deliveryCutOffDelta: z.number().optional(),
  deliveryDateEstimated: z.string().optional(),
  deliveryCountryCode: z.string().optional(),
});

export type InboundFormMessage = z.infer<typeof ZInboundFormMessage>;

export const ZOutboundFormMessage = z.object({
  action: z.enum(['CLOSE', 'SET_SESSION_ID', 'CLEAR_SESSION_ID', 'NAVIGATE', 'REFRESH_PAGE']).optional(),
  blurTitle: z.string().optional(),
  blinkInterval: z.number().optional(),
  status: z.enum(['BLUR', 'FOCUS']).optional(),
  commandStatus: ZCommandStatus,
  cartId: z.string().nullable().optional(),
  emitter: z.string(),
  orderUrl: z.string(),
  sessionKey: z.string().default('DEFAULT_KEY'),
  fbq: z
    .object({
      currency: z.string(),
      value: z.number(),
    })
    .optional()
    .nullable(),
  analytics: z
    .object({
      address: z.object({
        address1: z.string().optional(),
        province: z.string().optional(),
        provinceCode: z.string().optional(),
        city: z.string().optional(),
        country: z.string().optional(),
        countryCode: z.string().optional(),
        zip: z.string().optional(),
      }),
      customer: z
        .object({
          email: z.string().optional(),
          phone: z.string().optional(),
          firstName: z.string().optional(),
          lastName: z.string().optional(),
        })
        .optional(),
      id: z.string().optional(),
      name: z.string().optional(),
      orderId: z.string().optional(),
      currency: z.string().optional(),
      totalAmount: z.number().optional(),
      totalDiscount: z.number().optional(),
      discountCodes: z.array(z.string().optional()).optional(),
      cartId: z.string().optional(),
      shippingAmount: z.number().optional(),
      taxAmount: z.number().optional(),
      products: z.array(
        z.object({
          productId: z.string().optional(),
          variantId: z.string().optional(),
          variantTitle: z.string().optional(),
          name: z.string().optional(),
          brand: z.string().optional(),
          category: z.string().optional(),
          amount: z.number().optional(),
          quantity: z.number().optional(),
          sku: z.string().optional(),
        }),
      ),
    })
    .optional(),
});

export type OutboundFormMessage = z.infer<typeof ZOutboundFormMessage>;
