async function a(e) {
  if (e.order.outcome === "ready")
    try {
      const { origin: t } = new URL(e.sourceUrl ?? ""), r = btoa(JSON.stringify({ order: e.order }));
      if (`${t}/pages/just-post-purchase?data=${r}`.length > 2e3) {
        const o = await fetch(process.env.NEXT_PUBLIC_PASSTHROUGH_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ data: r })
        }).then((n) => n.json());
        location.href = `${t}/pages/just-post-purchase?passthrough=${encodeURIComponent(o.createdId)}`;
      } else
        location.href = `${t}/pages/just-post-purchase?data=${encodeURIComponent(r)}`;
    } catch {
      window.location.replace(e.order.url);
    }
}
function c() {
  return {
    redirectToOrderPage: a
  };
}
function s(e) {
  if (e === "shopify")
    return c();
}
export {
  s as getCMSInterceptors
};
