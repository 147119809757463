import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { Cart } from '@getjust/gateway';

import { CART_QUERY_KEY } from '$src/constants';
import { useBaseApiUrl } from '$src/hooks/client/useBaseApiUrl';
import { gateway } from '$src/http';

const MUTATION_CLEAR_GIFT_CARDS_KEY = 'MUTATION/CLEAR_GIFT_CARDS';

export const useClearGiftCards = () => {
  const baseUrl = useBaseApiUrl();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => gateway.post<Cart>(`${baseUrl}/clear-gift-cards`),
    mutationKey: [MUTATION_CLEAR_GIFT_CARDS_KEY, baseUrl],
    onSuccess: ({ data }: { data: Cart }) => {
      queryClient.setQueryData<Cart>([CART_QUERY_KEY, data.id], data);
    },
  });
};
